/* MainPage container styles */
.container {
  background-color: #343232;
  border-radius: 2px;
  margin: 8px 0;
  padding: 0;
}

.watchlist-container {
  height: 500px;
}

.chart-container {
  height: 500px;
}

.chat-container {
  height: 500px;
}

.aichat-container {
  height: 240px;
}

/* Navbar styles */
.navbar {
  height: 40px;
  background-color: #2a2727;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 10px;
  letter-spacing: 0.2em;
  color: #bcb6b6;
  font-family: 'Inter', sans-serif;
  width: 100%;
  box-sizing: border-box;
  border-top: none; /* Remove top border */
}

/* Watchlist styles */
.watchlist {
  padding-top: 40px; /* To accommodate the navbar */
}

.add-to-watchlist {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 8px;
  letter-spacing: 0.2em;
}

.add-icon {
  width: 14px;
  height: 14px;
  background-color: black;
  color: white;
  text-align: center;
  margin-right: 5px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 2px;
}

.watchlist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.watchlist-symbol,
.watchlist-price,
.watchlist-change {
  font-size: 10px;
  letter-spacing: 0.2em;
}

.watchlist-symbol {
  flex: 1;
  text-align: left;
}

.watchlist-price {
  flex: 1;
  text-align: center;
}

.watchlist-change {
  flex: 1;
  text-align: right;
}

.negative {
  color: #ee0e0e;
}

.positive {
  color: #00ffc2;
}

/* Import font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  line-height: normal;
}

.chat-footer {
  background-color: #2a2727;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: none; /* Ensure no border at the top */
  padding: 0 12px;
}

.chat-footer .MuiIconButton-root {
  margin: 0;
  padding: 0;
}

.chat-footer .MuiInputBase-root {
  flex-grow: 1;
  margin: 0;
}

.chat-footer .MuiIconButton-edgeEnd {
  margin: 0;
  padding: 0;
}

.chat-container {
  overflow-y: auto;
  padding: 8px;
  height: calc(100% - 40px); /* Ensures the container doesn't stretch below the footer */
  box-sizing: border-box; /* Ensures padding is included in the height calculation */
}

.chat-container::-webkit-scrollbar {
  width: 8px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ai-chat-container {
  display: flex;
}

.ai-chat-nav {
  flex: 0 0 180px;
  background-color: #2a2727;
  padding: 16px;
  position: relative;
}

@media (min-width: 768px) {
  .ai-chat-nav {
    flex: 0 0 190px;
  }
}

.ai-chat-nav span {
  color: #bcb6b6;
  font-size: 10px;
  letter-spacing: 0.2em;
  text-align: left;
  display: block;
  margin-bottom: 16px;
}

.ai-chat-area {
  flex-grow: 1;
  background-color: #343232;
  padding: 16px;
  position: relative;
}

.search-bar {
  width: 70%;
  border-radius: 5px;
  background-color: #000;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
}

.send-button {
  margin-left: 16px;
  background-color: #00A3FF;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #FFF;
}

.footer-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #bcb6b6;
  font-size: 10px;
  letter-spacing: 0.2em;
  font-family: var(--font-inter);
  text-align: center;
}

.tradingview-widget-container {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}
